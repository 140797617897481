<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">套餐名称：</span><input type="text" v-model="form.packageName"/></li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0">
                <option value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">出售价格：</span><input type="text" v-model="form.guige"/> 元<i class="icon-alert-circle" title="价格为默认价格&#10;实际开卡中可以调整！"></i></li>
            <li class="up"><span class="import">提成比例：</span><input type="number" v-model="form.tichengbaifenbi" :disabled="$store.state.User.daoqishijian<0"/> %
            <img src="@/assets/images/vip.png"></li>
            <li class="up"><span class="import">固定提成：</span><input type="number" v-model="form.tichengguding" :disabled="$store.state.User.daoqishijian<0"/> 元
            <img src="@/assets/images/vip.png"></li>
            <li class="up"><span class="import">会员等级：</span>
              <select v-model="form.huiyuandengjiId" @change="sethuiyuandengjiName">
                <option value="0">无等级</option>
                <option :value="item.id" v-for="item in huiyuandengjiList" :key="item.id">{{item.VIPLVName}}</option> 
              </select><i class="icon-alert-circle" title="携带会员等级会覆盖会员现有的等级！&#10;比如现有一会员等级为最高等级，又购买携带等级为初级的套餐，&#10;此会员等级将变为初级，享受初级优惠规则。"></i></li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">金额</div>
            <li class="up"><span>得到金额：</span><input type="text" v-model="form.deyue"/></li>
            <li class="up"><span>赠送金额：</span><input type="text" v-model="form.zengsongyue"/></li>
            <li class="up"><span>得到积分：</span><input type="text" v-model="form.dejifen"/></li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">次卡</div>
            <div class="qiehuan_xunhuan" v-for="(item , i) in cishuList" :key="i">
              <div class="qiehuan_del" @click="delXunhuan(i)"><i class="icon-x"></i></div>
              <li class="up"><span>消费项目：</span>
                <select v-model="item.xiangmuId" @change="setXiangmuName($event,i)">
                  <option :value="xiangmu.id" v-for="xiangmu in xiangmuList" :key="xiangmu.itemName">{{xiangmu.itemName}}</option>
                </select>
              </li>
              <li class="up"><span>项目次数：</span><input type="number" v-model="item.num"/></li>
              <li class="up"><span>有效期限：</span>
                <select v-model="item.youxiaoqi">
                  <option value="100">永久</option>
                  <option value="1">1年</option>
                  <option value="2">2年</option>
                  <option value="3">3年</option>
                  <option value="4">4年</option>
                  <option value="5">5年</option>
                  <option value="6">6年</option>
                  <option value="7">7年</option>
                  <option value="8">8年</option>
                  <option value="9">9年</option>
                  <option value="10">10年</option>
                  <option value="15">15年</option>
                  <option value="20">20年</option>
                  <option value="30">30年</option>
                </select>
              </li>
            </div>
            <div class="qiehuan_button" @click="addXiangMu">添加</div>
          </ul>
          <ul>
            <li class="up"><span>备注信息：</span><textarea v-model="form.details"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy,testStr} from '@/utils/util'
export default {
  name:'psinfo',
  props:{
    MmsData:Object,
    title:String, 
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
          this.cishuList=this.defcishuList
        }else{
          this.form=deepCopy(newD.data)
          this.cishuList=this.form.cishuList
          this.delcishuList=deepCopy(this.cishuList).map(e=>e.id)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      tab:1,
      xiangmuList:[],
      huiyuandengjiList:[],
      cishuObj:{
        num:null,
        xiangmuId:null,
        xiangmuName:'',
        youxiaoqi:100,
      },
      delcishuList:[],
      cishuList:[
        {
          num:null,
          xiangmuId:null,
          xiangmuName:'',
          youxiaoqi:100,
        },
      ],
      defcishuList:[
        {
          num:null,
          xiangmuId:null,
          xiangmuName:'',
          youxiaoqi:100,
        },
      ],
      storeList:[],
      form:{
        packageName:'',
        fenpeimendianId:'0',
        fenpeimendianName:'全部门店',
        guige:'',
        tichengbaifenbi:0,
        tichengguding:0,
        huiyuandengjiId:0,
        huiyuandengjiName:'无等级',
        details:'',
        deyue:'0',
        dejifen:'0',
        zengsongyue:'0',
      },
      defform:{
        packageName:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.jibieId==0?"全部门店":this.$store.state.User.fenpeimendianName,
        guige:'',
        tichengbaifenbi:0,
        tichengguding:0,
        huiyuandengjiId:0,
        huiyuandengjiName:'无等级',
        details:'',
        deyue:'0',
        dejifen:'0',
        zengsongyue:'0',
      }
    }
  },
  created() {this.init()},
  components: {},
  mounted() {},
  methods: {
    init(){
      if(this.MmsData.new){
        this.form=deepCopy(this.defform)
        this.cishuList=this.defcishuList
      }else{
        this.form=deepCopy(this.MmsData.data)
        this.cishuList=this.form.cishuList
        this.delcishuList=deepCopy(this.cishuList).map(e=>e.id)
      }
      if(this.$store.state.User.jibieId==0) this.getStore()
      this.getItemData()
      this.getVIPLVData()
    },
    delXunhuan(index){
      this.cishuList.splice(index,1)
    },
    addXiangMu(){
      this.cishuList.push(deepCopy(this.cishuObj))
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
        
      })
    },
    getItemData(){
      axios.post('/item/list',{fenpeimendianId:this.form.fenpeimendianId,oncika:1}).then((res) => {
        if(res.code===5000){
          this.xiangmuList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getVIPLVData(){
      axios.post('/viplv/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.huiyuandengjiList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    sethuiyuandengjiName(){
      let huiyuandengji=this.huiyuandengjiList.find(e=>e.id==this.form.huiyuandengjiId)
      if(huiyuandengji) this.form.huiyuandengjiName=huiyuandengji.VIPLVName
      else this.form.huiyuandengjiName='无等级'
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName='全部门店'
      this.getItemData()
      this.getVIPLVData()
    },
    setXiangmuName(c,num){
      let xiangmu=this.xiangmuList.find(e=>e.id==c.target.value)
      if(xiangmu) this.cishuList[num].xiangmuName=xiangmu.itemName
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.packageName)){
        this.$Mesg({
          content: '套餐名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.fenpeimendianId)){
        this.$Mesg({
          content: '分配门店不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.guige)){
        this.$Mesg({
          content: '出售价格不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      let cishuArr=this.cishuList.filter((e,i)=>{
        let index=this.cishuList.findIndex(a=>(a.xiangmuId==e.xiangmuId&&a.youxiaoqi==e.youxiaoqi))
        if(i==index&&e.num&&e.xiangmuId) return true
        else{
          this.cishuList[index].num+=e.num
          return false
        }
      })
      axios.post(this.MmsData.new?'/packages/add':'/packages/edit',{...this.form,...{cishuList:cishuArr,delcishuList:this.delcishuList}}).then((res) => {
        if(res.code===5000){
         this.$Mesg({content: this.MmsData.new?'新增套餐成功！':'修改套餐成功！'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
