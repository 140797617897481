<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>套餐名称：</p>
          <input type="text" v-model="form.packageName" @keypress.enter="getData(1)">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <ul>
        <li v-for="item in dataList" :key="item.id">
            <h3>{{item.packageName}}</h3>
            <p><span>门店：</span>{{item.fenpeimendianName}}</p>
            <p><span>规格：</span>{{item.guige}}元套餐</p>
            <p><span>会员：</span>{{item.huiyuandengjiName}}</p>
            <p><span>详情：</span>{{returnInfo(item)}}</p>
            <!-- <p class="about"><span>[ {{item.chuangjianrenName}} ]</span><span>{{item.chuangjianshijian.substr(0,10)}}</span></p> -->
            <p class="going" v-if="countPow(item.fenpeimendianId)" @click="showInfo(item)" v-pow:2602>套餐详情&gt;</p>
            <div class="del" v-if="countPow(item.fenpeimendianId)" @click="delOneData(item.id,item.packageName)" v-pow:2603><i class="icon-x"></i></div>
        </li>
      </ul>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2601>新增套餐</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'

export default {
  name: 'packageSettings',
  data() {
    return {
      infoShow: false,
      dataList:[],
      storeList:[],
      title:'',
      form:{
        packageName:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
     this.getData(1)
     if(this.$store.state.User.jibieId==0) this.getStore()
  },
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/packages/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    returnInfo(obj){
      const{guige,deyue,dejifen,zengsongyue,cishuList}=obj
      let str=`充${guige}元 ${deyue?'得'+deyue+'元':''}${dejifen?'得'+dejifen+'积分':''}${zengsongyue?' 送'+zengsongyue+'元':''}`
      if(cishuList.length){
        str+=' 得 '
        cishuList.forEach(e=>{
          const {num,xiangmuName}=e
          str+=`${xiangmuName}*${num} `
        })
      }
      return str
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/packages/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增套餐"
      this.MmsData.new=true
      this.infoShow=true
    },
    showInfo(item){
      this.title=item.packageName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    }
  },
}
</script>
